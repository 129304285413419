import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import PageLoading from "../Components/PageLoading/PageLoading";
import FooterShort from "../Components/FooterShort";
import Context from "../Context";

function TandC() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isM4myshot, setBackground } = useContext(Context);
  setBackground("white");
  let count = 0;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        isM4myshot
          ? "https://storage.googleapis.com/memshot_album_metadata/policies%26tandc/m4myshot/tandc.json"
          : "https://storage.googleapis.com/memshot_album_metadata/policies%26tandc/tandc.json"
      );
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <div className="containFull">
      <Navbar />
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="w-full h-full px-[5.26%] lg:px-[8.33%]  mb-[60px] mt-[42px] lg:mt-[84px] text-[#000] min-h-[60vh]">
            <span className="font_semibold_40_24">{data?.heading}</span>
            <div className="flex flex-col pt-[30px]">
              <span className="pb-[10px] font_bold_24_18">
                {data?.question}
              </span>
              <div className="flex flex-col font_normal_21_16">
                {data &&
                  data.termsAndConditions &&
                  data.termsAndConditions.map((obj) => {
                    count++;
                    return (
                      <div className="flex">
                        <span>{count}.</span>
                        <span className="pb-[10px] pl-2">{obj.tandc}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <FooterShort />
        </>
      )}
    </div>
  );
}

export default TandC;
