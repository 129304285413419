import React, { createContext, useState } from "react";

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [backfromHome, setBackFromHome] = useState(false);
  const [userTitle, setUserTitle] = useState("");
  const [userCoverImage, setUserCoverImage] = useState();
  const [userdescription, setUserDescription] = useState("");
  const [extraApprovalAccesMail, setExtraApprovalAccessmail] = useState("");
  const [wishSelected, setWishSelected] = useState(false);
  const [fileShareSelected, setFileShareSelected] = useState(false);
  const [selectedWishpackage, setSelectedWishpackage] = useState({});
  const [selectedPhotosharePackage, setSelectedPhotosharePackage] = useState(
    {}
  );
  const [invCoverImage, setInvCoverImage] = useState({});
  const [title, setTitle] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [thanksImage, setThanksImage] = useState({});
  const [background, setBackground] = useState("transparent");
  const isM4myshot = process.env.REACT_APP_BUILD_TYPE == "m4myshot";

  return (
    <Context.Provider
      value={{
        backfromHome,
        setBackFromHome,
        userTitle,
        setUserTitle,
        userCoverImage,
        setUserCoverImage,
        userdescription,
        setUserDescription,
        extraApprovalAccesMail,
        setExtraApprovalAccessmail,
        selectedWishpackage,
        setSelectedWishpackage,
        selectedPhotosharePackage,
        setSelectedPhotosharePackage,
        wishSelected,
        setWishSelected,
        fileShareSelected,
        setFileShareSelected,
        invCoverImage,
        setInvCoverImage,
        title,
        setTitle,
        uploadedImages,
        setUploadedImages,
        thanksImage,
        setThanksImage,
        background,
        setBackground,
        isM4myshot,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
