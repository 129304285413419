import React from "react";
import styles from "./home.module.css";
import events from "../../Assets/Images/Home/events.png";
import restaurant from "../../Assets/Images/Home/restaurant.png";
import corporates from "../../Assets/Images/Home/corporates.png";
import photographers from "../../Assets/Images/Home/photographers.png";
import { useNavigate } from "react-router-dom";

function Pitch() {
  const navigate = useNavigate();
  return (
    <div className={styles.containerPink}>
      <div className={styles.pitchDiv}>
        <p className="font_normal_52_32">
          memshots.com/<span className="text-[#A3A3A3]">foryou</span>{" "}
        </p>
        <div className={`${styles.pitchScrollSection} no-scrollbar`}>
          {/* Photographers */}

          {/* <div>
            <a
              href="https://photographers.memshots.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={photographers} alt="" />
              <p className="font_semibold_24_21 mt-[16px]">
                Memshots <span className="dmSerifRegularItalic">for </span>
                Photographers
              </p>
              <p className="font_medium_16_14 opacity-[0.8] mt-[12px]">
                Advanced tools help photographers create timeless masterpieces.
              </p>
              <span className="text-[#3171F6] font_medium_16_14 mt-[16px]">
                Explore More
              </span>
            </a>
          </div> */}

          {/* Corporates */}
          <div onClick={() => navigate("corporates")}>
            <img src={corporates} alt="" />
            <p className="font_semibold_24_21 mt-[16px]">
              Memshots <span className="dmSerifRegularItalic">for </span>
              Corporates
            </p>
            <p className="font_medium_16_14 opacity-[0.8] mt-[12px]">
              Transform your corporate events into unforgettable experiences.
            </p>
            <span className="text-[#3171F6] font_medium_16_14 mt-[16px]">
              Explore More
            </span>
          </div>
          {/* Gifting */}
          <div onClick={() => navigate("gifts")}>
            <img src={events} alt="" />
            <p className="font_semibold_24_21 mt-[16px]">
              Memshots <span className="dmSerifRegularItalic">for </span>
              Gifting
            </p>
            <p className="font_medium_16_14 opacity-[0.8] mt-[12px]">
              Capture and preserve joyous moments at events like weddings,
              birthdays, etc.
            </p>
            <span className="text-[#3171F6] font_medium_16_14 mt-[16px]">
              Explore More
            </span>
          </div>
          {/* Restaurants */}
          <div onClick={() => navigate("restaurants")}>
            <img src={restaurant} alt="" />
            <p className="font_semibold_24_21 mt-[16px]">
              Memshots <span className="dmSerifRegularItalic">for </span>
              Restaurants
            </p>
            <p className="font_medium_16_14 opacity-[0.8] mt-[12px]">
              Elevate your dining experience with memorable moments captured.
            </p>
            <span className="text-[#3171F6] font_medium_16_14 mt-[16px]">
              Explore More
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pitch;
