import React, { useContext, useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router";
import AuthContext from "../../AuthContext";
import SignupPage from "../../Pages/Signup/SignupPage";
import axios from "axios";
import photoShareBgImage from "../../Assets/Images/Home/photoshareImage.png";
import { Link } from "react-router-dom";

function PhotoShareTool({
  setFileShareSelected,
  setSelectedPhotosharePackage,
  packagePricing,
  sharedMemoriesBaseAmount,
}) {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);

  const handleSubmit = () => {
    navigate("buynow");
  };

  return (
    <>
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div className={styles.container}>
        <div className={styles.wishToolDiv}>
          <div className="w-full">
            <p className="font_normal_40_32 dmSerifRegularItalic ">
              Share memories with special people!
            </p>
          </div>
          <div className="mt-[40px] lg:mt-[10px] w-full flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
              <p className="font_semibold_32_24">Shared Memories Album</p>
              <p className="mt-[16px]  font_normal_16">
                Memshots is introducing an advanced Shared memories, created to
                simplify the process of sharing and reminiscing about your
                memories like never before.
              </p>
              <p className="mt-[12px]  font_normal_16">
                With Memshots' user-friendly platform, effortlessly sharing your
                photos with friends, family, and colleagues becomes a seamless
                experience, ensuring that each special moment is not only
                honored but also treasured for years to come.
              </p>
              <p className="font_semibold_18 mt-[40px]">{`Starting at ₹ ${sharedMemoriesBaseAmount}`}</p>
            </div>
            <div className="mt-[20px] lg:mt-0">
              <img
                src={photoShareBgImage}
                alt=""
                className="w-full object-cover max-w-[374px]"
              />
            </div>
          </div>
          <div className={styles.wishToolPricing}>
            <p className="font_bold_24 mt-[40px]">Ready to dive in?</p>
            <div className={styles.wishToolPricingSection}>
              {/* <p className="dmSerif_italic_blue font_normal_24">
                Try a free demo today or buy it.
              </p> */}
              <div className="flex gap-[12px]">
                {/* <button
                  onClick={() => {
                    navigate("sharedmemoriesdemo");
                  }}
                  className={styles.tryDemoButton}
                >
                  Try Demo
                </button> */}
                <Link
                  to={
                    "https://app.memshots.com/177corporatetour/sharedalbum?from=memshotscorporates"
                  }
                >
                  <button className={styles.tryDemoButton}>View Sample</button>
                </Link>
                <button
                  onClick={() => {
                    setFileShareSelected(true);
                    setSelectedPhotosharePackage(
                      packagePricing?.photoShare &&
                        packagePricing?.photoShare[0]
                    );
                    handleSubmit();
                  }}
                  className={styles.buyNowButton}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoShareTool;
