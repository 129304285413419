import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import PageLoading from "../Components/PageLoading/PageLoading";
import FooterShort from "../Components/FooterShort";
import Context from "../Context";

function PrivacyPolicy() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isM4myshot, setBackground } = useContext(Context);
  setBackground("white");
  let count = 0;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        isM4myshot
          ? "https://storage.googleapis.com/memshot_album_metadata/policies%26tandc/m4myshot/privacyPolicy.json"
          : "https://storage.googleapis.com/memshot_album_metadata/policies%26tandc/privacyPolicy.json"
      );
      setData(response?.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  return (
    <div className="containFull">
      <Navbar />
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="w-full h-full px-[5.26%] lg:px-[8.33%]  mb-[60px] mt-[42px] lg:mt-[84px] flex flex-col text-[#000] min-h-[60vh]">
            {data &&
              data.privacyPolicy &&
              data.privacyPolicy.map((obj) => {
                return (
                  <>
                    {obj.heading && (
                      <span
                        className={`mb-[20px] font_bold_24_18 first:text-[24px] lg:first:text-[40px]`}
                      >
                        {obj.heading}
                      </span>
                    )}
                    {obj.paragraph && (
                      <span className={`mb-[30px] font_normal_21_16`}>
                        {obj.paragraph}
                      </span>
                    )}
                  </>
                );
              })}
          </div>
          <FooterShort />
        </>
      )}
    </div>
  );
}

export default PrivacyPolicy;
