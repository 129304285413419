import React, { useContext, useEffect, useState } from "react";
// import { eventDatas } from "../../constants/Constants";
import axios from "axios";
import styles from "./home.module.css";
import Context from "../../Context";

function Events() {
  const [data, setData] = useState([]);
  const { isM4myshot } = useContext(Context);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(
        isM4myshot ? "/Json/EventsMyshot.json" : `/Json/Events.json`
      );
      setData(response?.data?.events);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.eventsDiv}>
        <div className="w-full">
          <p className="font_semibold_32_24 max-w-[600px]">
            Events which used{" "}
            <span className="dmSerifRegularItalic font_normal_32_24">
              {isM4myshot ? "M4Myshot" : "Memshots"}
            </span>
          </p>
        </div>
        <div className={styles.eventsSection}>
          {data?.map((item, i) => (
            <div key={i} className={styles.eventsCards}>
              <div
                className={styles.eventLogoSection}
                style={{ background: item?.logoBgColor }}
              >
                <img src={item?.image} alt="" />
              </div>
              <div className={styles.eventDetailSection}>
                <div>
                  <p className="font_semibold_21_18 text-[#161616]">
                    {item?.event}
                  </p>
                  <button className={styles.eventsUsers}>
                    {item?.participants} + people used{" "}
                    {isM4myshot ? "m4myshot" : "memshots"}
                  </button>
                </div>
                <p className="font_normal_10 text-[#161616]">
                  <span className="opacity-60">
                    {isM4myshot ? "m4myshot.com" : "memshots.com"}
                  </span>{" "}
                  {item?.organiser}{" "}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Events;
