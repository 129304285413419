import React, { useContext } from "react";
import styles from "./home.module.css";
import albumImage from "../../Assets/Images/Home/SelectionToolImage.jpg";
import goToIcon from "../../Assets/Images/Home/goToIcon.svg";
import { Link } from "react-router-dom";
import Context from "../../Context";

function SelectionTool() {
  const { isM4myshot } = useContext(Context);

  return (
    <div className={styles.container}>
      <div className={styles.faceDetectionDiv}>
        <div className={styles.faceDetectionTitle}>
          <p className="font_semibold_72_32 ">Photo Selection</p>
          <p className="font_normal_16 opacity-[0.8] max-w-[436px]">
            Solving the problems of passing pendrives and back to back
            corrections. Users have the liberty to select their photos at any
            time, from any location, on any device.
          </p>

          {/* <div className={styles.albumAbsolute}>
            <img src="shootmatesLogo.png" alt="" />
            <p>Memshots for Photographers</p>
          </div> */}
        </div>
        <img
          src={albumImage}
          alt=""
          className="w-full shrink-0  object-cover my-[40px] rounded-[16px]"
        />
        <div className={styles.albumButtonsSection}>
          <p className="font_bold_24 max-w-[580px]">
            Share event photos effortlessly via web links, allowing customers to
            easily select favourites from own mobile phones.
          </p>
          <div className="flex gap-[20px]">
            {/* {!isM4myshot && (
              <a
                href="https://photographers.memshots.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className={`${styles.tryDemoButton} flex items-center gap-[8px]`}
                >
                  <span>Explore</span>
                  <img src={goToIcon} alt="" />
                </button>
              </a>
            )} */}
            <Link
              to={
                isM4myshot
                  ? "https://selection.m4myshot.com/HarshanRithika/select?from=m4myshotdemo"
                  : "https://selection.memshots.com/HarshanRithika/select?from=memshotsdemo"
              }
            >
              <button className={styles.buyNowButton}>Try Demo</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectionTool;
