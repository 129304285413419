import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import memshotsIcon from "../Assets/Images/memshotsIcon.png";
import instaIcon from "../Assets/Images/instaIcon.png";
import youtubeIcon from "../Assets/Images/youtubeIcon.png";
import { Link } from "react-router-dom";
import FooterShort from "../Components/FooterShort";
import axios from "axios";
import NavbarNew from "../Components/NavbarNew";
import Context from "../Context";
import m4LogoBlack from "../Assets/Images/m4LogoBlack.png";

function Contact() {
  const [teamData, setTeamData] = useState([]);
  const { isM4myshot } = useContext(Context);
  const contactdata = async () => {
    try {
      const response = await axios.get("/Json/MemshotsTeam.json");
      setTeamData(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contactdata();
  }, []);

  return (
    <div className="containFull">
      <NavbarNew />
      <div className="w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[50px] xl:mb-[100px]">
        <div className="w-full mt-[46px] lg:mt-[80px] mb-[40px] lg:mb-[60px] bg-[#F9F9F9] rounded-[16px] p-[20px] lg:p-[60px]">
          <div className="flex justify-between w-full">
            <div className="text-left text-[#161616]">
              <p className="font_semibold_52_28  max-w-[532px]">
                Get in touch with us for more information
              </p>
              <p className="mt-[14px] font_normal_18_16 max-w-[345px]">
                if you need help, have a questions, have any doubts, we’re here
                for you
              </p>
            </div>
            <img
              src={isM4myshot ? m4LogoBlack : memshotsIcon}
              alt=""
              className="h-[79px] hidden lg:block"
            />
          </div>
          <div className="w-full h-[1px] bg-[#3171F6] opacity-[0.2] my-[40px] lg:mt-[78px] lg:mb-[30px]"></div>
          <div className="flex flex-col gap-[32px] lg:gap-0 lg:flex-row lg:justify-between">
            <div className="flex flex-col lg:flex-row gap-[24px] lg:gap-[60px]">
              <div className="text-left flex flex-col gap-[24px]">
                <span className="font_semibold_16 text-[#3171F6]">Phone</span>
                <div className="flex flex-col gap-[12px] font_normal_16 text-[#161616]">
                  {isM4myshot ? (
                    <span>+1 206 822 3339</span>
                  ) : (
                    <>
                      <span>+91 9986619796</span>
                      <span>+91 8089829538</span>
                    </>
                  )}
                </div>
              </div>
              <div className="text-left flex flex-col gap-[24px]">
                <span className="font_semibold_16 text-[#3171F6]">Email</span>
                <div className="flex flex-col gap-[12px] font_normal_16 text-[#161616]">
                  {isM4myshot ? (
                    <a
                      href="mailto:hello@m4myshot.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>hello@m4myshot.com</span>
                    </a>
                  ) : (
                    <a
                      href="mailto:hello@memshots.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>hello@memshots.com</span>
                    </a>
                  )}
                </div>
              </div>
              {!isM4myshot && (
                <div className="text-left flex flex-col gap-[24px]">
                  <span className="font_semibold_16 text-[#3171F6]">
                    WhatsApp
                  </span>
                  <div className="flex flex-col gap-[12px] font_normal_16 text-[#161616]">
                    <a
                      href="https://wa.me/8089829538"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>+91 9986619796</span>
                    </a>
                    <a
                      href="https://wa.me/9567183183"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>+91 8089829538</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-[20px]">
                <a
                  href={
                    isM4myshot
                      ? "https://www.instagram.com/m4myshot/"
                      : "https://www.instagram.com/memshots_/"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instaIcon}
                    alt=""
                    className="w-[32px] h-[32px] cursor-pointer"
                  />
                </a>
                {!isM4myshot && (
                  <a
                    href="https://www.youtube.com/@MEMSHOTS_COM"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={youtubeIcon}
                      alt=""
                      className="w-[32px] h-[32px] cursor-pointer"
                    />
                  </a>
                )}
              </div>
              <img
                src={memshotsIcon}
                alt=""
                className="w-[34px] h-[30px] block lg:hidden"
              />
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-[#D9D9D9] opacity-[0.6]"></div>

        <div className="mt-[40px] lg:mt-[60px] flex flex-col text-[#161616]">
          <span className="font_medium_16 text-[#F96149]">ABOUT COMPANY</span>
          <span className="mt-[16px] font_semibold_52_32 ">
            {isM4myshot ? "M4Myshot" : "Memshots"}
          </span>
          <p className="mt-[20px] max-w-[553px] text-left font_normal_18_16 ">
            At {isM4myshot ? "M4Myshot" : "Memshots"} Private Limited, we
            understand that every photograph holds a story, a sentiment, and a
            piece of our lives. Our mission is to transcend the ordinary,
            offering a suite of cutting-edge solutions that seamlessly blend
            technology and emotion.
          </p>
          {!isM4myshot && (
            <div className="mt-[60px] text-[#161616]">
              <p className="font_semibold_32_24">Meet the team</p>
              <div className="w-full mt-[32px] lg:mt-[20px] grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5 gap-x-[10px] lg:gap-x-[20px] gap-y-[20px] lg:gap-y-[40px]">
                {teamData?.contactUs?.map((obj, i) => {
                  return (
                    <div key={i} className="shrink-0 w-full flex flex-col">
                      <img
                        src={obj?.image}
                        alt=""
                        className="w-full aspect-square object-cover"
                      />
                      <span className="mt-[16px] lg:mt-[10px] font_semibold_18">
                        {obj?.name}
                      </span>
                      <span className="mt-[8px] lg:mt-[5px] font_normal_13">
                        {obj?.job}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <FooterShort />
    </div>
  );
}

export default Contact;
