import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Hamburger from "./Hamburger";
import memshotsLogoWhite from "../Assets/Images/memshotsLogoWhite.png";
import memshotsLogoBlack from "../Assets/Images/memshotsLogoBlack.png";
import m4LogoBlack from "../Assets/Images/m4LogoBlack.png";
import m4LogoWhite from "../Assets/Images/m4LogoWhite.png";
import Context from "../Context";
import AuthContext from "../AuthContext";
import SignupPage from "../Pages/Signup/SignupPage";
import axios from "axios";
import newUserIconSmall from "../Assets/Images/newUserIconSmall.svg";
import userIcon from "../Assets/Images/userIcon.svg";

function Navbar({ packagePricing }) {
  const {
    setWishSelected,
    setSelectedWishpackage,
    loading,
    background,
    isM4myshot,
  } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [popup, setPopup] = useState(false);
  const { currentUser, setPreviousLocation, googleSignOut } =
    useContext(AuthContext);

  useEffect(() => {
    setPreviousLocation(location.pathname);
  }, []);

  useEffect(() => {
    if (currentUser) {
      setPopup(false);
    }
  }, [currentUser]);

  const handleSubmit = () => {
    navigate("/buynow");
  };
  const logo = () => {
    if (background == "transparent") {
      if (isM4myshot) {
        return m4LogoWhite;
      } else {
        return memshotsLogoWhite;
      }
    } else {
      if (isM4myshot) {
        return m4LogoBlack;
      } else {
        return memshotsLogoBlack;
      }
    }
  };
  return (
    <>
      {popup && <SignupPage setOpen={setPopup} open={popup} />}
      <div
        style={{
          background: background,
        }}
        className="containFull   flex justify-center w-full h-fit px-[20px] sm:px-[40px] lg:px-[80px] fixed top-0 z-[100]"
      >
        <div className="  py-[20px]  flex items-center justify-between w-full max-w-[1440px] ">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="text-[#161616] cursor-pointer hover:opacity-[0.8]"
          >
            <img
              src={logo()}
              alt=""
              className="w-[120px] h-[34px] sm:w-[220px] sm:h-[44px] object-contain"
            />
          </div>

          <div
            style={{
              color: `${background === "transparent" ? "#fff" : "#000"}`,
            }}
            className=" gap-[40px] desktopOnly"
          >
            {/* <button
              onClick={() => {
                navigate("/poster");
              }}
              className="font_semibold_16 text-[#161616] hover:text-[#4b494b]"
            >
              Poster Memories
            </button> */}
            {/* <button
              onClick={() => {
                navigate("/pricing");
              }}
              className="font_semibold_16 text-[#161616] hover:text-[#4b494b]"
            >
              Pricing
            </button> */}

            <button
              onClick={() => {
                navigate("/clients");
              }}
              className="font_normal_16  "
            >
              Clients
            </button>
            <button
              onClick={() => {
                navigate("/contact");
              }}
              className="font_normal_16  "
            >
              Contact
            </button>
            {!isM4myshot && (
              <>
                {/* <button
                onClick={() => {
                setWishSelected(true);
                setSelectedWishpackage(
                  packagePricing?.wish && packagePricing?.wish[0]
                );
                handleSubmit();
               }}
                className="font_semibold_16 text-[#161616] hover:text-[#4b494b]"
                 >
                   Buy Now
                   </button> */}
                {!loading && (
                  <div className="cursor-pointer">
                    {!currentUser ? (
                      <img
                        onClick={() => {
                          setPopup(true);
                        }}
                        src={userIcon}
                        alt=""
                      />
                    ) : (
                      <img
                        onClick={() => {
                          navigate("/userprofile");
                        }}
                        src={
                          currentUser?.photoURL
                            ? currentUser?.photoURL
                            : newUserIconSmall
                        }
                        alt=""
                        className="rounded-full w-[32px] h-[32px]"
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mobileOnly">
            <Hamburger
              setWishSelected={setWishSelected}
              setSelectedWishpackage={setSelectedWishpackage}
              setPreviousLocation={setPreviousLocation}
              handleSubmit={handleSubmit}
              currentUser={currentUser}
              googleSignOut={googleSignOut}
              setPopup={setPopup}
              packagePricing={packagePricing}
              background={background}
            />
          </div>

          {/* <div className="w-[140px]"></div> */}
        </div>
      </div>
    </>
  );
}

export default Navbar;
