import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles/styles.module.css";
import Context from "../Context";

function FooterShort() {
  const navigate = useNavigate();
  const { isM4myshot } = useContext(Context);
  return (
    <div className={styles.containerBlackPadding}>
      <div className={styles.footerDiv}>
        <p
          onClick={() => {
            navigate("/");
          }}
          className=" font_normal_13 text-[#787878] cursor-pointer text-center"
        >
          <span className="text-[#fff] font_normal_16">
            {isM4myshot ? "m4myshot.com" : "memshots.com"}
          </span>
        </p>
        <div className="text-[#fff] font_normal_13 flex justify-between w-full mt-[12px]">
          <p>© 2024 {isM4myshot ? "m4myshot.com" : "memshots.com"}</p>
          <p>{isM4myshot ? "hello@m4myshot.com" : "hello@memshots.com"}</p>
        </div>
      </div>
    </div>
  );
}

export default FooterShort;
